<template>
    <div>
        <div class="google-map" ref="googleMap"></div>
        <template v-if="Boolean(this.google) && Boolean(this.map)">
            <slot :google="google" :map="map" />
        </template>
    </div>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";

export default {
    props: {
        mapConfig: Object,
        apiKey: String,
    },

    data() {
        return {
            google: null,
            map: null,
        };
    },

    async mounted() {
        const loader = new Loader({
            apiKey: this.apiKey,
            version: "weekly",
            libraries: ["places"],
        });
        const googleMapApi = await loader.load();
        this.google = googleMapApi;
        this.initializeMap();
    },

    methods: {
        initializeMap() {
            const mapContainer = this.$refs.googleMap;
            this.map = new this.google.maps.Map(mapContainer, this.mapConfig);
        },
    },
};
</script>
<style lang="scss" scoped>
.google-map {
    width: 100%;
    min-height: 100%;
}
</style>
