import Vue from "vue";
import Vuex from "vuex";
import * as firestore from "@/store/modules/firestore.js";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: { firestore }
});

export default store;
