<template>
    <div class="container">
        <div class="section">
            <h3 class="title text-center">
                Medium Articles
            </h3>
            <div class="row">
                <div class="col-md-4">
                    <card type="blog" plain>
                        <img
                            slot="image"
                            class="img rounded img-raised"
                            src="img/python.jpg"
                        />
                        <h6 class="category text-info">Python</h6>
                        <h4 class="card-title">
                            <a
                                href="https://thepoints.medium.com/email-automation-with-python-in-3-steps-6cea8660746"
                                target="_blank"
                                >Email Automation in Python</a
                            >
                        </h4>
                        <p class="card-description">
                            In this article, you will learn all the steps
                            required to configure an automatic Python job to
                            send email from your workstation
                            <a
                                href="https://thepoints.medium.com/email-automation-with-python-in-3-steps-6cea8660746"
                                target="_blank"
                            >
                                Read More
                            </a>
                        </p>
                    </card>
                </div>
                <div class="col-md-4">
                    <card type="blog" plain>
                        <img
                            slot="image"
                            class="img rounded img-raised"
                            src="img/matrix.jpg"
                        />
                        <h6 class="category text-info">iOS</h6>
                        <h4 class="card-title">
                            <a
                                href="https://thepoints.medium.com/how-to-hide-my-email-add-custom-email-domains-with-icloud-mail-and-ios-15-a591e8949099"
                                target="_blank"
                                >How to Hide My Email, Add Custom Email Domains
                                with iCloud Mail and iOS 15</a
                            >
                        </h4>
                        <p class="card-description">
                            Stay anonymous, and stop receiving unwanted ads
                            emails with "Hide My Email"
                            <a
                                href="https://thepoints.medium.com/how-to-hide-my-email-add-custom-email-domains-with-icloud-mail-and-ios-15-a591e8949099"
                                target="_blank"
                            >
                                Read More
                            </a>
                        </p>
                    </card>
                </div>
                <div class="col-md-4">
                    <card type="blog" plain>
                        <img
                            slot="image"
                            class="img rounded img-raised"
                            src="img/firebase.png"
                        />
                        <h6 class="category text-info">
                            Google Cloud Platform
                        </h6>
                        <h4 class="card-title">
                            <a
                                href="https://thepoints.medium.com/upload-data-to-firebase-cloud-firestore-with-10-line-of-python-code-1877690a55c6"
                                target="_blank"
                                >Upload Data to Firebase Cloud Firestore with 10
                                lines of Python Code</a
                            >
                        </h4>
                        <p class="card-description">
                            With only 10 lines of Python code, you can upload
                            your data to Firestore, and retrieve data back.
                            <a
                                href="https://thepoints.medium.com/upload-data-to-firebase-cloud-firestore-with-10-line-of-python-code-1877690a55c6"
                                target="_blank"
                            >
                                Read More
                            </a>
                        </p>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Card } from "@/components";
export default {
    name: "medium-articles",
    components: {
        Card,
    },
};
</script>

<style></style>
