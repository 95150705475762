<template>
    <GoogleMapLoader :mapConfig="mapConfig" :apiKey="apiKey">
        <template slot-scope="{ google, map }">
            <GoogleMapCluster :google="google" :map="map">
                <GoogleMapMarker
                    v-for="marker in markers"
                    :key="marker.id"
                    :marker="marker"
                    :google="google"
                    :map="map"
                />
            </GoogleMapCluster>
            <GoogleMapLine
                v-for="line in lines"
                :key="line.id"
                :path.sync="line.path"
                :google="google"
                :map="map"
            />
        </template>
    </GoogleMapLoader>
</template>

<script>
import GoogleMapLoader from "./GoogleMap/GoogleMapLoader.vue";
import GoogleMapLine from "./GoogleMap/GoogleLine.vue";
import GoogleMapMarker from "./GoogleMap/GoogleMarker.vue";
import GoogleMapCluster from "./GoogleMap/GoogleMapCluster.vue";
import { mapSettings } from "@/constants/mapSettings";
import { API_KEY } from "@/constants/map.config";

export default {
    components: {
        GoogleMapLoader,
        GoogleMapMarker,
        GoogleMapLine,
        GoogleMapCluster,
    },
    props: {
        markers: Array,
        lines: Array,
    },

    data() {
        return {
            apiKey: API_KEY,
        };
    },

    computed: {
        mapConfig() {
            return {
                ...mapSettings,
                center: this.mapCenter,
                zoom: 7,
            };
        },

        mapCenter() {
            let size = this.markers.length;
            let lng =
                this.markers.reduce((a, marker) => a + marker.position.lng, 0) /
                size;
            let lat =
                this.markers.reduce((a, marker) => a + marker.position.lat, 0) /
                size;
            return { lng: lng, lat: lat };
        },
    },
};
</script>
