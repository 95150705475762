<template>
    <div class="wrapper blog-posts">
        <div class="page-header page-header-small">
            <parallax
                class="page-header-image"
                style="background-image: url('img/bg44.jpg') ;"
            >
            </parallax>
            <div class="content-center">
                <div class="row">
                    <div class="col-md-8 ml-auto mr-auto text-center">
                        <h2 class="title">
                            A Place to Share and Discover New Stories
                        </h2>
                        <a
                            href="https://twitter.com/home"
                            class="btn btn-primary btn-round  btn-icon"
                            target="_blank"
                        >
                            <i class="fab fa-twitter"></i>
                        </a>
                        <a
                            href="https://www.instagram.com/"
                            class="btn btn-primary btn-round  btn-icon"
                            target="_blank"
                        >
                            <i class="fab fa-instagram"></i>
                        </a>
                        <a
                            href="https://hung.today/about"
                            class="btn btn-primary btn-round  btn-icon"
                            target="_blank"
                        >
                            <i class="now-ui-icons users_single-02"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="main">
            <template v-if="articles">
                <top-headlines :articles="articles"></top-headlines>
            </template>
            <medium-articles></medium-articles>
        </div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-6 px-0">
                    <card
                        type="background"
                        class="card-fashion"
                        style="background-image: url('img/project13.jpg')"
                    >
                        <div class="card-title text-left">
                            <h2>
                                <a href="#pablo">
                                    Washington Coast Trip
                                </a>
                            </h2>
                        </div>
                        <div class="card-footer text-left">
                            <div class="stats">
                                <span>
                                    <i class="now-ui-icons users_circle-08"></i
                                    >Hung Nguyen
                                </span>
                                <span>
                                    <i class="now-ui-icons tech_watch-time"></i>
                                    March 1, 2022
                                </span>
                            </div>
                            <div class="stats-link pull-right">
                                <a
                                    href="https://www.cabana.life/"
                                    class="footer-link"
                                    target="_blank"
                                    >Cabana</a
                                >
                            </div>
                        </div>
                    </card>
                </div>
                <div class="col-md-6 px-0">
                    <Map class="travel-map" :markers="markers"></Map>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash";
import Map from "@/components/Map.vue";
import MediumArticles from "./tools/MediumArticles.vue";
import TopHeadLines from "./tools/TopHeadLines.vue";
// import newsapi from "@/services/newsapi";
import { Card } from "@/components";
import { mapActions, mapState } from "vuex";
export default {
    components: {
        Map,
        Card,
        [MediumArticles.name]: MediumArticles,
        [TopHeadLines.name]: TopHeadLines,
    },
    data: () => ({
        articles: null,
        markers: [
            {
                id: "Olympic Game Farm",
                position: { lng: -123.1468407, lat: 48.1351759 },
            },
            {
                id: "Port Angeles",
                position: { lng: -123.4307413, lat: 48.118146 },
            },
            {
                id: "Lake Crescent",
                position: { lng: -123.8131963, lat: 48.058231 },
            },
            {
                id: "Hoh Rainforest - Olympic National Park",
                position: { lng: -124.2632303, lat: 47.8258758 },
            },
            {
                id: "Ruby Beach",
                position: { lng: -124.4154024, lat: 47.7108733 },
            },
            {
                id: "Kalaloch Beach 4",
                position: { lng: -124.3875054, lat: 47.6514723 },
            },
            {
                id: "Kalaloch Campground (Stay over night)",
                position: { lng: -124.3762041, lat: 47.6130419 },
            },
            {
                id: "Aberdeen",
                position: { lng: -123.8157218, lat: 46.9753708 },
            },
            {
                id: "Westport",
                position: { lng: -124.1040613, lat: 46.8900903 },
            },
            {
                id: "Raymond",
                position: { lng: -123.7329385, lat: 46.6864887 },
            },
            {
                id: "World’s Largest Oyster",
                position: { lng: -123.8109143, lat: 46.6663408 },
            },
            {
                id: "Visit Long Beach Peninsula",
                position: { lng: -124.0545091, lat: 46.3313283 },
            },
            {
                id: "Cape Disappointment State Park",
                position: { lng: -124.0653812, lat: 46.2995548 },
            },
        ],
    }),
    computed: {
        ...mapState("firestore", ["newsapi_articles"]),
    },
    methods: {
        ...mapActions("firestore", ["fetchNewsApiHeadLines"]),
    },
    mounted() {
        this.fetchNewsApiHeadLines().then(res => {
            this.articles = res;
        });
        // newsapi
        //     .getTopHeadLines()
        //     .then(res => {
        //         this.articles = _.assign(res.data["articles"]);
        //     })
        //     .catch(console.log);
    },
};
</script>

<style lang="scss" scoped>
.travel-map {
    height: 500px;
}
</style>
