import Vue from "vue";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCIUYFptJLc_w-_cIrJZHiyUxOdf9LLAcM",
  authDomain: "hung-today.firebaseapp.com",
  projectId: "hung-today",
  storageBucket: "hung-today.appspot.com",
  messagingSenderId: "661739271384",
  appId: "1:661739271384:web:30501a37ae2cf4cb4f13ad",
  measurementId: "G-R3TQK7B094"
};

const FIREBASEAPP = firebase.initializeApp(firebaseConfig);
const FIREBASEDB = FIREBASEAPP.firestore();
Vue.prototype.$analytics = firebase.analytics();

export { FIREBASEAPP, FIREBASEDB };
