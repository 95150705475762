<template>
    <navbar
        position="fixed"
        :transparent="transparent"
        :color-on-scroll="colorOnScroll"
        type="primary"
        menu-classes="ml-auto"
    >
        <!-- eslint-disable-next-line -->
        <template slot-scope="{ toggle, isToggled }">
            <router-link v-popover:popover1 class="navbar-brand" to="/">
                Home
            </router-link>
            <el-popover
                ref="popover1"
                popper-class="popover"
                placement="bottom"
                width="200"
                trigger="hover"
            >
                <div class="popover-body">Back to Homepage</div>
            </el-popover>
        </template>
        <template slot="navbar-menu">
            <drop-down tag="li" title="Menu">
                <nav-link class="dropdown-item">
                    <i class="now-ui-icons shopping_box"></i> Blog
                </nav-link>
                <nav-link class="dropdown-item" to="/">
                    <i class="now-ui-icons shopping_box"></i> About
                </nav-link>
                <nav-link class="dropdown-item" to="/tools">
                    <i class="now-ui-icons shopping_box"></i> Tools
                </nav-link>
            </drop-down>
        </template>
    </navbar>
</template>

<script>
import { DropDown, NavbarToggleButton, Navbar, NavLink } from "@/components";
import { Popover } from "element-ui";

export default {
    name: "main-navbar",
    props: {
        transparent: Boolean,
        colorOnScroll: Number,
    },
    components: {
        DropDown,
        Navbar,
        NavbarToggleButton,
        NavLink,
        [Popover.name]: Popover,
    },
};
</script>

<style scoped></style>
