<template>
    <div>
        <slot></slot>
    </div>
</template>

<script>
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { POINT_MARKER, POINT_MARKER_HOVERED } from "@/constants/mapSettings";
export default {
    name: "map-cluster",
    props: {
        google: {
            type: Object,
            required: true,
        },
        map: {
            type: Object,
            required: true,
        },
    },
    computed: {
        isBigEnough() {
            return this.$children.length > 50;
        },
    },
    methods: {
        mountOnMap(cluster) {
            const renderer = {
                render({ count, position }, stats) {
                    const color = count > 10 ? "red" : "blue";
                    return new google.maps.Marker({
                        label: {
                            text: String(count),
                            color: color,
                            fontSize: "15px",
                        },
                        position,
                        icon: "img/zoom.svg",
                        // adjust zIndex to be above other markers
                        zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
                    });
                },
            };
            if (this.isBigEnough) {
                const options = {
                    map: this.map,
                    markers: cluster,
                    renderer: renderer,
                };
                const markerClusterer = new MarkerClusterer(options);
                markerClusterer["markers"].forEach(marker => {
                    let l = {
                        text: marker.marker.id,
                        color: "#006400",
                        fontWeight: "600",
                        fontSize: "1.5em",
                    };
                    marker.addListener("mouseover", () => {
                        marker.setAnimation(1);
                        marker.setIcon(POINT_MARKER_HOVERED);
                        marker.setLabel(l);
                    });
                    marker.addListener("mouseout", () => {
                        marker.setAnimation(0);
                        marker.setIcon(POINT_MARKER);
                        marker.setLabel(null);
                    });
                });
            } else {
                cluster.forEach(marker => {
                    let l = {
                        text: marker.marker.id,
                        color: "#006400",
                        fontWeight: "600",
                        fontSize: "1.5em",
                    };
                    marker.setMap(this.map);
                    marker.addListener("mouseover", () => {
                        marker.setAnimation(1);
                        marker.setIcon(POINT_MARKER_HOVERED);
                        marker.setLabel(l);
                    });
                    marker.addListener("mouseout", () => {
                        marker.setAnimation(0);
                        marker.setIcon(POINT_MARKER);
                        marker.setLabel(null);
                    });
                });
            }
        },
    },
    mounted() {
        let cluster = this.$children.map(obj => obj.markerObj);
        this.mountOnMap(cluster);
    },
};
</script>

<style></style>
