<template>
  <div class="wrapper">
    <div class="page-header page-header-small header-filter" filter-color="">
      <parallax
        class="page-header-image"
        style="background-image: url('img/bg16.jpg')"
      >
      </parallax>
      <div class="container">
        <div class="photo-container">
          <img v-lazy="'img/hung.jpg'" alt="Hung" />
        </div>
        <h3 class="title pb-1">Hung Nguyen</h3>
        <p class="category">Artist 🧑🏻‍💻</p>
        <h3 class="title">
          Learn the rules like a pro, so you can break them like an artist.
          <br />
          <span class="description text-right">-Pablo Picasso</span>
        </h3>
      </div>
    </div>

    <div class="section">
      <div class="container">
        <div class="button-container">
          <a
            href="https://www.linkedin.com/in/hungln/"
            class="btn btn-linkedin btn-round btn-lg btn-icon"
            rel="tooltip"
            title="Follow me on LinkedIn"
            target="_new"
          >
            <i class="fab fa-linkedin"></i>
          </a>
          <a
            href="https://github.com/hunglnguyen"
            class="btn btn-github btn-round btn-lg btn-icon"
            rel="tooltip"
            title="Connect me on GitHub"
            target="_new"
          >
            <i class="fab fa-github"></i>
          </a>
          <a
            href="https://twitter.com/76u11g"
            class="btn btn-twitter btn-round btn-lg btn-icon"
            rel="tooltip"
            title="Follow me on Twitter"
            target="_new"
          >
            <i class="fab fa-twitter"></i>
          </a>
        </div>
        <h3 class="title">About Me</h3>
        <h5 class="description text-black">
          I love games of chance and probability theory 📈. I play a lot of
          soccer ⚽️. When injured, I enjoy watching the games 💻 and making
          wagers 💵. And when none of the above is true, I hit the gym 🏃🏻,
          practice yoga or do nothing 🧎🏻.
        </h5>
        <div class="row">
          <div class="col-12">
            <h4 class="title text-center">Previous Research</h4>
          </div>
          <div class="col-md-4">
            <card plain>
              <a
                slot="image"
                href="https://ieeexplore.ieee.org/document/7998331"
                target="_blank"
              >
                <img class="img-raised rounded" src="img/load_forecast.jpg" />
              </a>

              <a
                href="https://ieeexplore.ieee.org/document/7998331"
                target="_blank"
              >
                <h4 class="card-title">
                  Short-term Electricity Load Forecasting with Time Series
                  Analysis
                </h4>
              </a>
              <h6 class="category text-primary">
                2017 IEEE International Conference on PHM
              </h6>
              <p class="card-description">
                In this paper, several time series models will be introduced for
                the purpose of generating forecasts of short-term load demand,
                at an hourly interval, based on data made available by the
                Electric Reliability Council of Texas (ERCOT).
              </p>
            </card>
          </div>

          <div class="col-md-4">
            <card plain>
              <a
                slot="image"
                href="https://www.iaqf.org/resources/Documents/2018%20Student%20Competition/U%20Washington_Stratton%20Oakquant.pdf"
                target="_blank"
              >
                <img class="img-raised rounded" src="img/trading.jpg" />
              </a>

              <a
                href="https://www.iaqf.org/resources/Documents/2018%20Student%20Competition/U%20Washington_Stratton%20Oakquant.pdf"
                target="_blank"
              >
                <h4 class="card-title">
                  On the Relationship between Momentum Trading and Option
                  Strategies
                </h4>
              </a>
              <h6 class="category text-primary">
                2018 IAQF Winning Research Paper
              </h6>
              <p class="card-description">
                We investigate the relationship between momentum trading and
                options strategies on the S&P500 by implementing simple momentum
                filter knownn as MA crossover and the Black-Scholes option
                pricing formula to construct 4 portfolios over the period from
                Jan 2007 to Dec 2017.
              </p>
            </card>
          </div>
          <div class="col-md-4">
            <card plain>
              <a
                slot="image"
                href="https://www.emerald.com/insight/content/doi/10.1108/SEF-08-2018-0264/full/html"
                target="_blank"
              >
                <img class="img-raised rounded" src="img/bitcoin.jpg" />
              </a>

              <a
                href="https://www.emerald.com/insight/content/doi/10.1108/SEF-08-2018-0264/full/html"
                target="_blank"
              >
                <h4 class="card-title">
                  Constructing Cointegrated Cryptocurrency Portfolios for
                  Statistical Arbitrage
                </h4>
              </a>
              <h6 class="category text-primary">
                2019 Studies in Economics and Finance
              </h6>
              <p class="card-description">
                This paper aims to present a methodology for constructing
                cointegrated portfolios consisting of different cryptocurrencies
                and examines the performance of a number of trading strategies
                for the cryptocurrency portfolios.
              </p>
            </card>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <h4 class="title text-center">Education</h4>
          </div>
          <div class="col-md-12 px-0">
            <card
              type="background"
              class="card-background-product card-no-shadow"
              style="background-image: url('img/uw.jpg')"
            >
              <h6 class="category">
                M.S. in Computational Finance and Risk Management
              </h6>
              <h3 class="card-title">University of Washington</h3>
              <p class="card-description">
                At UW, I met great friends and we won the pregtigous 2018 IAQF
                research challenge. I also had great mentors who guided me
                through challenging research and helped publish my work in
                cryptocurrency.
              </p>

              <a
                href="https://depts.washington.edu/compfin/"
                class="btn btn-round btn-md btn-neutral"
                rel="tooltip"
                title="Follow me on Instagram"
                target="_new"
              >
                <i class="now-ui-icons ui-1_send"></i>
                View
              </a>
            </card>
          </div>
          <div class="col-md-12 px-0">
            <card
              type="background"
              raised
              class="card-background-product card-no-shadow"
              style="background-image: url('img/spokane.jpg')"
            >
              <h6 class="category">
                B.A. in Applied Mathematics <br />
                B.A. in Economics
              </h6>
              <h3 class="card-title">EWU</h3>
              <p class="card-description">
                At EWU, I developed a solid foundation in probability theory &
                statistics, and learned to apply its applications in various
                areas of economics, and actuarial science.
              </p>
              <a
                href="https://www.ewu.edu/cstem/mathematics"
                class="btn btn-round btn-md btn-danger"
                rel="tooltip"
                title="Follow me on Instagram"
                target="_new"
              >
                <i class="now-ui-icons ui-1_send"></i>
                View
              </a>
            </card>
          </div>
        </div>

        <div class="row">
          <div class="col-md-5 ml-auto mr-auto">
            <h2 class="title">Send me a message</h2>
            <ValidationObserver ref="contact_form">
              <form
                role="form"
                id="contact-form"
                method="post"
                @submit.prevent="checkForm"
                @reset.prevent="reset"
              >
                <label>Your name</label>
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <fg-input
                    placeholder="Your name ... "
                    v-model="form.name"
                    addon-left-icon="now-ui-icons users_circle-08"
                    :required="!!errors.length"
                    :error="errors[0]"
                  >
                  </fg-input>
                </ValidationProvider>
                <label>Email address</label>
                <ValidationProvider
                  name="email"
                  rules="email|required"
                  v-slot="{ errors }"
                >
                  <fg-input
                    placeholder="Email Here..."
                    v-model="form.email"
                    addon-left-icon="now-ui-icons users_circle-08"
                    :required="!!errors.length"
                    :error="errors[0]"
                  >
                  </fg-input>
                </ValidationProvider>

                <div class="form-group">
                  <label
                    >Your message
                    <span class="blockquote-footer">
                      {{ `${msgCharCounts}/100` }}
                    </span></label
                  >
                  <textarea
                    name="message"
                    class="form-control"
                    id="message"
                    minlength="2"
                    rows="6"
                    placeholder="Please enter your message here..."
                    v-model="form.message"
                  ></textarea>
                </div>
                <div class="col-md-6">
                  <n-checkbox v-model="form.notRobot">
                    I'm not a robot
                  </n-checkbox>
                </div>
                <div class="submit text-center">
                  <!-- <n-button
                                    type="primary"
                                    round
                                    @click.native="sendMsg"
                                    >Send Message</n-button
                                > -->
                  <button type="submit" class="btn btn-primary btn-round">
                    Send
                  </button>
                </div>
              </form>
            </ValidationObserver>
          </div>
          <!-- Office, Contact Info -->
          <div class="col-md-5 ml-auto mr-auto">
            <info-section
              type="primary"
              class="mt-5"
              icon="now-ui-icons location_pin"
            >
              <h4 class="info-title">Office</h4>
              <p>
                UW CoMotion Labs, Startup Hall <br />
                1100 NE Campus Pkwy, Suite 200 <br />
                Seattle, WA 98105
              </p>
            </info-section>

            <info-section type="primary" icon="now-ui-icons tech_mobile">
              <h4 class="info-title">Contact Info</h4>
              <p>
                Hung Nguyen <br />
                (206) 717-5795<br />
                contact@hung.today
              </p>
            </info-section>
          </div>
        </div>
      </div>
    </div>

    <div>
      <alert type="success" class="text-center" v-model="msgStatus" dismissible>
        <i class="alert-icon"> </i>
        Message was successfully sent!
      </alert>
    </div>
    <div>
      <alert type="danger" class="text-center" v-model="msgWarning" dismissible>
        <i class="alert-icon"></i>
        Please confirm that you are not a robot.
      </alert>
    </div>
    <div>
      <!-- <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d21667.06057220615!2d-122.32633559441504!3d47.65191332198429!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x549014f391bf3e67%3A0x827fc729870b9ccd!2sStartup%20Hall!5e0!3m2!1sen!2sus!4v1627169387807!5m2!1sen!2sus"
                width="100%"
                height="500"
                style="border:0;"
                allowfullscreen=""
                loading="lazy"
            ></iframe> -->
    </div>
  </div>
</template>
<script>
import {
  Alert,
  Badge,
  Button,
  InfoSection,
  FormGroupInput,
  Card,
  Tabs,
  TabPane,
  Checkbox
} from "@/components";
import Vue from "vue";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { FIREBASEDB } from "@/store/config/firebase";
import { required, email } from "vee-validate/dist/rules";
import * as VueGoogleMaps from "vue2-google-maps";
extend("email", email);
extend("required", {
  validate(value) {
    return {
      required: true,
      valid: ["", null, undefined].indexOf(value) === -1 && !/^\s+$/.test(value)
    };
  },
  message: "This field is required",
  computesRequired: true
});
Vue.use(VueGoogleMaps, {
  load: {
    key: "api"
  }
});
export default {
  name: "profile-page",
  bodyClass: "profile-page",
  components: {
    Alert,
    InfoSection,
    Card,
    Tabs,
    TabPane,
    Badge,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [FormGroupInput.name]: FormGroupInput,
    ValidationProvider,
    ValidationObserver
  },
  computed: {
    emailRules() {
      return "email";
    },
    msgCharCounts() {
      return this.form.message ? this.form.message.length : 0;
    }
  },
  data() {
    return {
      msgStatus: false,
      msgWarning: false,
      form: {
        name: "",
        email: "",
        message: "",
        notRobot: false
      }
    };
  },
  methods: {
    updateMsgStatus(msgStatus) {
      this.msgStatus = msgStatus;
    },
    sendMsg() {},
    checkForm(e) {
      if (!this.form.notRobot) {
        this.msgWarning = true;
        setTimeout(() => {
          this.msgWarning = false;
        }, 2000);
      } else {
        this.$refs.contact_form.validate().then(success => {
          if (!success) {
            return;
          } else {
            this.msgStatus = true;
            const docRef = FIREBASEDB.collection("mail");
            docRef.add({
              from: "contact@hung.today",
              replyTo: this.form.email,
              to: ["contact@hung.today", "76u11g@gmail.com"],
              message: {
                subject: `Inquiry from ${this.form.email} - hung.today`,
                html: `<p>The following message is from ${this.form.name}</p><p>${this.form.message}</p>`
              }
            });
            setTimeout(() => {
              this.msgStatus = false;
            }, 3000);
          }
        });

        if (this.form.email && this.form.name) {
          this.msgStatus = true;
          setTimeout(() => {
            this.msgStatus = false;
            this.resetForm();
          }, 2000);
        }
      }
    },
    resetForm() {
      this.$refs.contact_form.reset();
      this.form.email = "";
      this.form.name = "";
      this.form.message = "";
      this.form.notRobot = false;
    },
    checkError(v) {
      console.log(v);
    }
  }
};
</script>
<style lang="scss" scoped>
.alert {
  position: fixed;
  width: 100%;
  bottom: -1.7vh;
}
</style>
