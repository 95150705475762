<template>
    <footer
        class="footer"
        :class="{ [`footer-${type}`]: type }"
        :data-background-color="backgroundColor"
    >
        <div class="container">
            <nav>
                <ul>
                    <li>
                        <a href="https://hung.today/about" target="_newtab">
                            About
                        </a>
                    </li>
                </ul>
            </nav>
            <div class="copyright">Knowledge &reg; {{ year }} Hung</div>
        </div>
    </footer>
</template>
<script>
export default {
    name: "inline-footer",
    props: {
        backgroundColor: String,
        type: String,
    },
    data() {
        return {
            year: new Date().getFullYear(),
        };
    },
};
</script>
<style></style>
