<template>
    <div class="wrapper">
        <div class="page-header bg-dark">
            <div class="content">
                <div class="container justify-content-center">
                    <div class="row mb-4">
                        <div class="col ml-auto mr-auto display-4">
                            Current Time:
                        </div>
                        <div class="col ml-auto mr-auto display-4 text-left">
                            {{ currentHour }}: {{ currentMinute }}:
                            {{ currentSecond }}
                        </div>
                    </div>
                    <div class="row">
                        <div
                            class="col-4 test d-flex flex-column justify-content-between"
                        >
                            <div class="h2">
                                Alarm
                            </div>
                            <button
                                class="btn btn-primary btn-lg"
                                type="button"
                            >
                                Start Alarm
                            </button>
                        </div>
                        <div
                            class="col-4 test d-flex flex-column justify-content-between"
                        >
                            <div class="h2 ">Timer</div>
                            <div>
                                <input
                                    placeholder="HH"
                                    v-model="hourLeft"
                                    max="23"
                                    maxlength="2"
                                    size="3"
                                    style="font-size:2em;"
                                />:
                                <input
                                    placeholder="10"
                                    v-model="minuteLeft"
                                    max="59"
                                    maxlength="2"
                                    size="3"
                                    style="font-size:2em"
                                />:
                                <input
                                    placeholder="SS"
                                    v-model="secondLeft"
                                    max="59"
                                    maxlength="2"
                                    size="3"
                                    style="font-size:2em"
                                />
                            </div>
                            <button
                                class="btn btn-primary btn-lg"
                                type="button"
                            >
                                Start Timer
                            </button>
                        </div>
                        <div
                            class="col-4 test d-flex flex-column justify-content-between"
                        >
                            <div class="h2">Stopwatch</div>
                            <div class="display-4 text-left">
                                {{ hours }}:{{ minutes }}:{{ seconds }}:{{
                                    miliseconds
                                }}
                            </div>
                            <button
                                class="btn btn-primary btn-lg"
                                type="button"
                                @click="startStopWatch"
                                :disabled="stopWatchRunning"
                            >
                                Start Stopwatch
                            </button>
                            <button
                                class="btn btn-primary btn-lg"
                                type="button"
                                @click="pauseStopWatch"
                                :disabled="!stopWatchRunning"
                            >
                                Pause Stopwatch
                            </button>
                            <button
                                class="btn btn-primary btn-lg"
                                type="button"
                                @click="resetStopWatch"
                            >
                                Reset Stopwatch
                            </button>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col ml-auto">
                            <div>Funny Thalia</div>
                        </div>
                    </div>
                </div>
            </div>
            <inline-footer backgroundColor="white"></inline-footer>
        </div>
    </div>
</template>
<script>
import Footer from "@/layout/Footer.vue";
export default {
    name: "tools",
    components: {
        [Footer.name]: Footer,
    },
    data: () => ({
        currentTime: "",
        hourLeft: "",
        minuteLeft: "",
        secondLeft: "",
        currentHour: "",
        currentMinute: "",
        currentSecond: "",
        hh: 0,
        mm: 0,
        ss: 0,
        ms: 0,
        stopwatch: null,
        stopWatchRunning: false,
        startTime: 0,
        elapsedTime: 0,
    }),
    computed: {
        hours() {
            return this.hh.toString().padStart(2, "0");
        },
        minutes() {
            return this.mm.toString().padStart(2, "0");
        },
        seconds() {
            return this.ss.toString().padStart(2, "0");
        },
        miliseconds() {
            return this.ms.toString().padStart(2, "0");
        },
    },
    methods: {
        showCurentTime() {
            let time = new Date();
            this.currentHour = time
                .getHours()
                .toString()
                .padStart(2, "0");
            this.currentMinute = time
                .getMinutes()
                .toString()
                .padStart(2, "0");
            this.currentSecond = time
                .getSeconds()
                .toString()
                .padStart(2, "0");
            this.currentTime = time.getTime();
        },
        displayTime() {
            let timeNow = new Date().getTime();
            this.elapsedTime = timeNow - this.timeStart;
            if (this.elapsedTime > 10) {
                this.ms = Math.floor((this.elapsedTime % 1000) / 10);
            }
            if (this.elapsedTime > 1000) {
                this.ss = Math.floor(this.elapsedTime / 1000) % 60;
            }
            if (this.elapsedTime > 60000) {
                this.mm = Math.floor(this.elapsedTime / 60000) % 60;
            }
            if (this.elapsedTime > 60 * 60000) {
                this.hh = Math.floor(this.elapsedTime / 360000);
            }
        },
        startStopWatch() {
            let self = this;
            this.stopWatchRunning = true;
            this.timeStart = new Date().getTime();
            if (this.elapsedTime > 0) {
                this.timeStart = this.timeStart - this.elapsedTime;
            }

            if (!this.stopwatch) {
                this.stopwatch = setInterval(this.displayTime, 10);
            }
        },
        pauseStopWatch() {
            clearInterval(this.stopwatch);
            this.stopwatch = null;
            this.stopWatchRunning = false;
        },
        resetStopWatch() {
            clearInterval(this.stopwatch);
            this.stopWatchRunning = false;
            this.stopwatch = null;
            this.startTime = 0;
            this.elapsedTime = 0;
            this.hh = 0;
            this.mm = 0;
            this.ss = 0;
            this.ms = 0;
        },
    },

    mounted() {
        setInterval(() => this.showCurentTime(), 1);
    },
};
</script>
<style lang="scss" scoped>
input {
    font-size: 2em;
    margin: 4px;
    text-align: right;
}
.test {
    border: 1px solid white;
}
</style>
