<template>
    <div class="container">
        <div class="section">
            <div class="row">
                <div class="col-lg-6 col-md-8 col-sm-5">
                    <div class="col-lg-6 col-md-8 col-sm-5">
                        <el-select
                            v-model="singleSelect.value"
                            placeholder="Category"
                            class="select-primary"
                        >
                            <el-option
                                v-for="option in singleSelect.options"
                                class="select-primary"
                                :key="option.label"
                                :value="option.label"
                                :label="option.label"
                            >
                            </el-option>
                        </el-select>
                    </div>
                </div>
            </div>
            <h3 class="title text-center">
                Today's Headlines on {{ singleSelect.value }}
            </h3>
            <div class="text-center">Updated Every Hour</div>
        </div>
        <div class="row align-items-center">
            <div
                class="col-md-4"
                v-for="article in filteredArticles"
                :key="article.url"
            >
                <collapse-transition :duration="10000" :delay="100">
                    <card type="blog" card-body-classes="text-center">
                        <img
                            slot="image"
                            class="img rounded"
                            :src="article.urlToImage"
                            @click="openLink(article.url)"
                        />
                        <h6 class="category text-danger">
                            <i class="now-ui-icons media-2_sound-wave"></i>
                            {{ article.source.name }}
                        </h6>
                        <h5 class="card-title">
                            <a :href="article.url" target="_blank">
                                {{ article.title }}
                            </a>
                        </h5>
                        <div class="card-footer">
                            <div class="author">
                                <span> {{ article.author }}</span>
                            </div>
                        </div>
                    </card>
                </collapse-transition>
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash";
import { Card, Button, DropDown } from "@/components";
import { Option, Select, Tag } from "element-ui";
import { CollapseTransition } from "vue2-transitions";

export default {
    name: "top-headlines",
    components: {
        Card,
        [Button.name]: Button,
        CollapseTransition,
        [Tag.name]: Tag,
        [Select.name]: Select,
        [Option.name]: Option,
        [DropDown.name]: DropDown,
    },
    props: {
        articles: {
            type: Array,
        },
    },
    data: () => ({
        singleSelect: {
            value: "Science",
            options: [
                {
                    label: "Business",
                },
                {
                    label: "Technology",
                },
                {
                    label: "Science",
                },
                {
                    label: "Health",
                },
            ],
        },
    }),
    computed: {
        filteredArticles() {
            return _.filter(this.articles, {
                "category": this.singleSelect.value.toLowerCase(),
            }).splice(0, 9);
        },
    },
    methods: {
        convertTime(timestamp) {
            let date = new Date(timestamp);
            return date.toDateString();
        },
        processArticleDescription(content) {
            if (!_.isEmpty(content)) {
                return content.split("[")[0];
            } else {
                return content;
            }
        },
        openLink(link) {
            window.open(link);
        },
        handleClose(tag) {
            this.tags.dynamicTags.splice(this.tags.dynamicTags.indexOf(tag), 1);
        },
    },
    watch: {
        articles: {
            handler(updatedArticles) {
                this.articles = updatedArticles;
            },
            immediate: false,
        },
    },
};
</script>

<style scoped lang="scss"></style>
