import firestore from "@/services/firestore";

function logError(error) {
  // eslint-disable-next-line no-restricted-syntax
  console.log("Error: \n", error);
}

export const namespaced = true;

export const state = {
  newsapi_articles: Array
};

export const mutations = {
  SET_NEWSAPI_ARTICLES(state, docs) {
    // state.experiences = Object.assign({}, docs);
    state.newsapi_articles = docs;
  }
};

export const actions = {
  fetchNewsApiHeadLines({ commit }) {
    return firestore
      .getArticles()
      .then(result => {
        let articles = [];
        result.forEach(x => {
          articles.push(x.data());
        });
        commit("SET_NEWSAPI_ARTICLES", articles);
        return articles;
      })
      .catch(logError);
  }
};

export const getters = {};
