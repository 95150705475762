import Vue from "vue";
import VueRouter from "vue-router";
import Profile from "../views/Profile.vue";
import Timer from "../views/Timer.vue";

import Navbar from "../layout/Navbar.vue";
import Footer from "../layout/Footer.vue";
import Tools from "@/views/Tools.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    components: {
      default: Profile,
      header: Navbar,
      footer: Footer
    },
    props: {
      footer: { backgroundColor: "black" },
      header: { colorOnScroll: 250 }
    }
  },
  {
    path: "/",
    name: "Home",
    components: {
      default: Profile,
      header: Navbar,
      footer: Footer
    },
    props: {
      footer: { backgroundColor: "black" },
      header: { colorOnScroll: 250 }
    }
  },
  {
    path: "/tools/timer",
    name: "timer",
    components: {
      default: Timer,
      header: Navbar
    },
    props: {
      footer: { backgroundColor: "black" },
      header: { colorOnScroll: 450 }
    }
  },
  {
    path: "/tools",
    name: "tools",
    components: {
      default: Tools,
      header: Navbar
    },
    props: {
      footer: { backgroundColor: "black" },
      header: { colorOnScroll: 250 }
    }
  }
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});

export default router;
