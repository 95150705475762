import { FIREBASEDB } from "@/store/config/firebase";

export default {
  async getArticles() {
    const snapshot = await FIREBASEDB.collection("newsapi").get();
    if (snapshot.empty) {
      return;
    }
    return await snapshot;
  }
};
