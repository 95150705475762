<script>
import { POINT_MARKER } from "@/constants/mapSettings";

export default {
    props: {
        google: {
            type: Object,
            required: true,
        },
        map: {
            type: Object,
            required: true,
        },
        marker: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        markerObj: null,
    }),

    mounted() {
        this.markerObj = new this.google.maps.Marker({
            position: this.marker.position,
            marker: this.marker,
            animation: 2,
            icon: POINT_MARKER,
        });
    },
    render() {},
};
</script>
